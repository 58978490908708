.menu-button {
  display: flex;
  padding: 4px;
  align-items: center;
  background-color: rgba(#ccc, 0.7);
  border-radius: 4px;
  &,
  * {
    cursor: pointer;
  }
  kbd {
    background-color: #fff;
    border-radius: 3px;
    margin-right: 5px;
    padding: 2px 8px;
    font-size: 1.2rem;
    font-family: "Courier New", Courier, monospace;
  }
  label {
    font-size: 1.2rem;
  }
}
