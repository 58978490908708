import "./global.scss";

import React from "react";
import ReactDOM from "react-dom";

import App from "./components/App";

const root = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  root
);
